export const dispatchEvent = (eventName, detail = {}) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail }));
};

export const eventListener = (eventName, callback) => {
  let data = null;

  window.addEventListener(eventName, (event) => {
    data = (event && event.detail) || {};
    callback(data);
  });
};