import KeenSlider from 'keen-slider';

import 'keen-slider/keen-slider.min.css';
import { WritingEffect } from './writingEffect';

export class Slider {
  constructor() {
    this.interval = 0;
    this.wrapper;

    this.arrowLeft;
    this.arrowRight;

    this.navigation = this.navigation.bind(this);
    this.setupSlider();
  }

  createDiv(className) {
    const div = document.createElement('div');
    const classNames = className.split(' ');
    classNames.forEach((name) => div.classList.add(name));
    return div;
  }

  removeElement(element) {
    element.parentNode.removeChild(element);
  }

  wrapperNavigator(container) {
    this.wrapper = this.createDiv('arrow-wrapper');
    container.appendChild(this.wrapper);
  }

  createArrows(slider) {
    this.arrowLeft = this.createDiv('arrow arrow__left');
    this.arrowLeft.addEventListener('click', () => {
      slider.prev();
    });

    this.arrowRight = this.createDiv('arrow arrow__right');
    this.arrowRight.addEventListener('click', () => {
      slider.next();
    });

    this.wrapper.appendChild(this.arrowLeft);
    this.wrapper.appendChild(this.arrowRight);
  }

  navigation(slider) {
    let timeout;
    let mouseOver = false;
    const writingEffect = new WritingEffect();

    function clearNextTimeout() {
      clearTimeout(timeout);
    }

    function nextTimeout() {
      const elements = slider?.slides || [];
      const amountOfElements = Math.max(elements.length - 1, 0);
      const currentSliderIndex = slider?.track.details.rel || 0;
      const previousSliderIndex = currentSliderIndex ? currentSliderIndex - 1 : amountOfElements;

      if (!(elements && elements.length)) return;

      const currentElement = elements[currentSliderIndex];
      const previousElement = elements[previousSliderIndex];

      clearTimeout(timeout);
      setTimeout(() => writingEffect.processCurrentViewTextType(currentElement), 100);
      writingEffect.removeClass('.title-with-typing', 'active', previousElement);
      writingEffect.removeClass('.subtitle-with-typing', 'active', previousElement);
      if (mouseOver) return;
      timeout = setTimeout(() => slider.next(), 5000);
    }

    slider.on("dragStarted", clearNextTimeout);
    slider.on("animationEnded", nextTimeout);
    slider.on("updated", nextTimeout);
    slider.on('created', () => {
      this.wrapperNavigator.call(this, slider.container);
      this.createArrows.call(this, slider);
      setTimeout(() => nextTimeout(), 1000);
    });
  }

  thumbnailSliderPlugin(main) {
    return (slider) => {
      function removeActive() {
        slider.slides.forEach((slide) => slide.classList.remove('active'));
      }

      function addActive(index) {
        slider.slides[index].classList.add('active');
      }

      slider.on('created', () => {
        addActive(slider.track.details.rel);
        main.on('animationStarted', (main) => {
          removeActive();
          const next = main.animator.targetIdx || 0;
          addActive(main.track.absToRel(next));
          slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
        });
      });
    };
  }


  setupSlider() {
    const slider = new KeenSlider('#whatWeAreSliderContent', {
      loop: true,
      detailsChanged: (slide) => {
        slide.slides.forEach((element, index) => {
          element.style.opacity = slide.track.details.slides[index].portion;
        });
      },
      renderMode: 'custom',
    }, [this.navigation]);

    new KeenSlider('#whatWeAreSliderOverlay', {
      loop: true,
      renderMode: 'custom',
    }, [this.thumbnailSliderPlugin(slider)]);
    return;
  }
}
