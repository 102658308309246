export const removeHash = () => {
  history.pushState("", document.title, window.location.pathname + window.location.search);
};

export const getURLParameters = () => {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map(param => param.split('='))
    .reduce((values, [key, value]) => {
      values[key] = value;
      return values;
    }, {});
};

export const getURLParameter = (parameter) => {
  const urlParameters = getURLParameters();
  return urlParameters[parameter];
};
