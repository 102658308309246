import Bowser from "bowser"; // ES6 (and TypeScript with --esModuleInterop enabled)

export const isMobile = window.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

export const isIOS = window.isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

export const isSmallDevice = window.innerWidth < 360;

export const getBrowserNameAndVersion = () => {
  try {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowser();
  } catch (error) {
    console.error(error);
    return {
      name: "Error",
      version: "0"
    };
  }
};

export const isIOSVersion11OrLess = (() => {
  const { name = '', version = 999 } = getBrowserNameAndVersion();
  return name.toLowerCase() === 'safari' && Number(version) <= 11;
})();