export class WritingEffect {

  removeClass(refElementString, className, parentElement) {
    const element = parentElement.querySelector(refElementString);
    if (!element) return;
    element.classList.remove(className);
  }

  typeWriter(text, element, currentTextIndex, speed) {
    if (currentTextIndex < text.length) {
      element.innerHTML = element.innerHTML.replace('_', '');
      element.innerHTML += text.charAt(currentTextIndex);

      if (currentTextIndex < text.length - 2) {
        element.innerHTML += '_';
      }

      currentTextIndex++;
      setTimeout(() => {
        currentTextIndex = this.typeWriter(text, element, currentTextIndex, speed);
      }, speed);
    } else {
      setTimeout(() => {
        element.classList.remove('running');
      }, 1000);
    }
    return currentTextIndex;
  }

  textTypingEffect(element, speed = 50) {
    let currentTextIndex = 0;
    const text = element.getAttribute('typing-text');
    const isRunning = element.classList.contains('running');
    if (isRunning) return;
    element.innerHTML = '';
    element.classList.add('running');
    setTimeout(() => {
      currentTextIndex = this.typeWriter(text, element, currentTextIndex, speed);
    }, 250);
  }

  processCurrentViewTextType(element) {
    const title = element.querySelector('.title-with-typing');
    const getTitleTypingText = title.getAttribute('typing-text');
    if (!getTitleTypingText) {
      title.setAttribute('typing-text', title.innerHTML);
    }
    this.textTypingEffect(title, 35);
    title.classList.add("active");

    const text = element.querySelector('.subtitle-with-typing');
    const getTextTypingText = text.getAttribute('typing-text');
    if (!getTextTypingText) {
      text.setAttribute('typing-text', text.innerHTML);
    }
    this.textTypingEffect(text, 15);
    text.classList.add("active");
  }

}