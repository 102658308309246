import "../styles/home.scss";

import Scroller from "./lib/scroller";
import "./lib/swiper";

import { querySelector, querySelectorAll } from './utils/index.utils';
import { eventListener } from "./utils/events.utils";
import { SCROLL_INTO_VIEW_EVT } from "./events/constants";
import { removeHash } from "./utils/browser.utils";
import { debounceLeading } from "./utils/common.utils";
import HomeForm from "./home/forms";
import { VideoResizer } from "./lib/videoResizer";
import { Slider } from "./lib/slider";
import { isIOSVersion11OrLess } from "./utils/responsive.utils";

export default class Home {
  constructor() {
    // this.scroller = window.scroller = new Scroller('#root');
    this.didNavigation = false;
    this.registerScrollEventListener();
  }

  registerScrollEventListener() {
    eventListener(SCROLL_INTO_VIEW_EVT, (event) => {
      setTimeout(() => {
        this.processCurrentViewTextType(event);
      }, 100);
    });
  }

  videoResponsive() {
    new VideoResizer("#player");
  }

  setupSlider() {
    new Slider();
  }

  setupScroller() {
    const scroller = this.scroller;

    const scrollerScrollEvent = (page) => {
      scroller.scroll(page);
    };

    const scrollerScrollToDebouncedEvent = debounceLeading((event) => {
      scroller.listenScroll(event);
    }, 100);

    document.addEventListener("wheel", (event) => {
      scrollerScrollToDebouncedEvent(event);
    });

    document.addEventListener("swipeUp", () => {
      scrollerScrollEvent(1);
    });
    document.addEventListener("swipeDown", () => {
      scrollerScrollEvent(-1);
    });

    document.addEventListener("keydown", (event) => {
      switch (event.keyCode) {
        case 40:
          return scrollerScrollEvent(1);
        case 38:
          return scrollerScrollEvent(-1);

        default:
          return;
      }
    });

    return;
  }

  handleHeaderLinkClick() {
    const header = document.querySelector("header");
    const contactLink = header.querySelector('a[href="/#contato"]');
    const contactIndex = 5;

    let touchEvent = "ontouchstart" in window ? "touchstart" : "click";

    if (contactLink) {
      contactLink.addEventListener(touchEvent, (event) => {
        event.preventDefault();
        const element = document.querySelector(".contact-title");
        const topPos = element.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
          top: topPos,
          behavior: "smooth",
        });
      });
    }

    if (window.location.hash.includes("contato")) {
      this.didNavigation = true;
      window.addEventListener("load", () => {
        this.scroller.setCurrentSectionIndex(contactIndex);
        setTimeout(() => {
          this.scroller.setCurrentSectionIndex(contactIndex);
        }, 1000);

        removeHash();
      });
    }
  }

  textTypingEffect(el, speed = 50) {
    let currentIdx = 0;
    const txt = el.getAttribute("typing-text");
    const isRunning = el.classList.contains("running");
    if (isRunning) {
      return;
    } else {
      el.classList.add("running");
    }

    el.innerHTML = "";

    function typeWriter() {
      if (currentIdx < txt.length) {
        el.innerHTML = el.innerHTML.replace("_", "");
        el.innerHTML += txt.charAt(currentIdx);

        if (currentIdx < txt.length - 2) {
          el.innerHTML += "_";
        }
        currentIdx++;
        setTimeout(typeWriter, speed);
      } else {
        setTimeout(() => {
          el.classList.remove("running");
        }, 1000);
      }
    }

    setTimeout(() => {
      typeWriter();
    }, 250);
  }

  processCurrentViewTextType(event) {
    const {
      detail: { element },
    } = event;
    const textTypingClass = "what-we-are-section-item";

    if (element && element.classList.contains(textTypingClass)) {
      const title = element.querySelector(".title-with-typing");
      const getTitleTypingText = title.getAttribute("typing-text");
      if (!getTitleTypingText) {
        title.setAttribute("typing-text", title.innerHTML);
      }
      this.textTypingEffect(title, 35);

      const text = element.querySelector(".subtitle-with-typing");
      const getTextTypingText = text.getAttribute("typing-text");
      if (!getTextTypingText) {
        text.setAttribute("typing-text", text.innerHTML);
      }
      this.textTypingEffect(text, 15);
    }
  }

  setupAccordion() {
    const accordionContentClass = ".accordion-content";
    const accordionShowContentClass = "show-acc-content";
    const accordionBtnClass = ".accordion";
    const accordionItems = querySelectorAll(".accordion-group .section-group");
    const activeClass = "active";

    function setServicesBg(src) {
      const sectionMainClass = ".services-section ";
      const sectionEl = querySelector(sectionMainClass);
      const imageShowClass = "show-service-bg";

      // Hide all
      sectionEl.querySelectorAll(".services-bg-image").forEach((el) => {
        el.classList.remove(imageShowClass);
      });

      if (src) {
        const serviceBgSelector = ".services-bg-image";

        const showEl = sectionEl.querySelector(
          `${serviceBgSelector}[src="${src}"]`
        );

        setTimeout(() => {
          showEl.classList.add(imageShowClass);
        }, 500);
      }
    }

    function hideAccordions() {
      return accordionItems.forEach((item) => {
        const btnEl = querySelector(accordionBtnClass, item);

        btnEl.classList.remove(activeClass);

        querySelector(accordionContentClass, item).classList.remove(
          accordionShowContentClass
        );
      });
    }

    let touchEvent =
      "ontouchstart" in window && isIOSVersion11OrLess ? "touchstart" : "click";

    accordionItems.forEach((item) => {
      item.addEventListener(touchEvent, (event) => {
        const itemClassList = querySelector(
          accordionContentClass,
          item
        ).classList;

        const btnEl = querySelector(accordionBtnClass, item);

        const itemClassListStr = itemClassList.toString();

        hideAccordions();

        let background = null;
        if (itemClassListStr.indexOf(accordionShowContentClass) === -1) {
          itemClassList.add(accordionShowContentClass);
          btnEl.classList.add(activeClass);
          background = item
            .querySelector(".section-background")
            .getAttribute("href");
        } else {
          itemClassList.remove(accordionShowContentClass);
        }

        setServicesBg(background);
      });
    });
  }

  setupFixedContactForm() {
    const formSelector = querySelector(".floating-contact-button");
    const contactBtn = querySelector(".open-form", formSelector);
    const closeBtn = querySelector(".close-btn", formSelector);
    const contactContainerEl = querySelector(
      ".floating-contact-container",
      formSelector
    );

    let touchEvent = "ontouchstart" in window ? "touchstart" : "click";

    contactBtn.addEventListener(touchEvent, (event) => {
      event.preventDefault();
      contactContainerEl.classList.add("show");
      formSelector.classList.add("show");
    });

    closeBtn.addEventListener(touchEvent, (event) => {
      event.preventDefault();
      contactContainerEl.classList.remove("show");
      formSelector.classList.remove("show");
    });
  }

  setupPhoneMaskToInput() {
    const handlePhone = (e) => {
      if (e.target.value) {
        const maskedPhone = phoneMask(e.target.value);
        e.target.value = maskedPhone;
      }
    };

    const phoneMask = (v) => {
      if (!v) return "";
      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{2})(\d{2})(\d)/, "+$1 ($2) $3");
      v = v.replace(/(\d)(\d{4})$/, "$1-$2");
      v.replace(/\D/g, "");
      return v;
    };

    const interval = setInterval(() => {
      const phoneInput = document.querySelector(".hs-input[type='tel']");

      if (phoneInput) {
        clearInterval(interval);
        phoneInput.maxLength = "19";
        phoneInput.value = "+55";
        phoneInput.onkeyup = handlePhone;
        phoneInput.focus({ focustVisible: false, preventScroll: true });
        phoneInput.blur();
      }
    }, 500);
  }

  onDomContentLoaded() {
    document.addEventListener("DOMContentLoaded", () => {
      this.videoResponsive();
      // this.setupScroller();
      this.setupSlider();
      this.handleHeaderLinkClick();
      this.setupAccordion();
      this.setupFixedContactForm();
      this.setupPhoneMaskToInput();
    });
  }

  init() {
    console.info("[home] init");
    this.onDomContentLoaded();
    new HomeForm();
  }
}

new Home().init();
