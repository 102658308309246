import Sha256 from 'crypto-js/sha256';

/**
 * Make a query selector for a specific element with a optional context
 *
 * @param {String} selector
 * @param {HTMLElement} [_context=window.document]
 * @returns
 */
export function querySelector(selector, _context = window.document) {
  try {
    return _context.querySelector(selector);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Make a query selector for all element with a optional context
 *
 * @param {String} selector
 * @param {HTMLElement} [_context=window.document]
 * @returns
 */
export function querySelectorAll(selector, _context = window.document) {
  try {
    return _context.querySelectorAll(selector);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export async function sha256(message) {
  const hasHex = await Sha256(message).toString();
  return hasHex;
}
export const IS_LOCALHOST_ENV = window.IS_LOCALHOST_ENV = (() => {
  const devEnv = [
    'localhost',
  ];

  const { hostname } = window.location;
  return devEnv.includes(hostname);
})();

export const IS_DEV_ENV = window.IS_DEV_ENV = (() => {
  const devEnv = [
    'vitrio.vitriofactory.com.br',
  ];

  const { hostname } = window.location;
  return IS_LOCALHOST_ENV || devEnv.includes(hostname);
})();

export const validators = (function validators() {
  return {
    CPF: function validateCPF(cpf) {
      cpf = cpf.replace(/\D*/gi, '');

      if (!cpf || cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
      var soma = 0;
      var resto;
      for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;
      if ((resto == 10) || (resto == 11)) resto = 0;
      if (resto != parseInt(cpf.substring(9, 10))) return false;
      soma = 0;
      for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;
      if ((resto == 10) || (resto == 11)) resto = 0;
      if (resto != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },
    CNPJ: function validateCNPJ(cnpj) {
      cnpj = cnpj.replace(/\D*/gi, '');

      if (!cnpj || cnpj.length != 14 ||
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;
      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) return false;
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) return false;
      return true;
    },
    Email: function Email(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  };
})();

export const masks = (function masks() {
  return {
    Tel: function maskTel(tel) {
      tel = tel.replace(/\D/g, "");
      tel = tel.replace(/^(\d)/, "($1");
      tel = tel.replace(/(.{3})(\d)/, "$1) $2");

      if (tel.length == 10) {
        tel = tel.replace(/(.{1})$/, "-$1");
      } else if (tel.length == 11) {
        tel = tel.replace(/(.{2})$/, "-$1");
      } else if (tel.length == 12) {
        tel = tel.replace(/(.{3})$/, "-$1");
      } else if (tel.length == 12) {
        tel = tel.replace(/(.{3})$/, "-$1");
      } else if (tel.length > 12) {
        tel = tel.replace(/(.{4})$/, "-$1");
      }
      return tel;
    },
    CPF: function maskCPF(cpf) {
      cpf = cpf.replace(/\D/g, "");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return cpf;
    },
    Plate: function maskPlate(plate) {
      plate = plate.replace(/-/g, "");
      first = plate.slice(0, 3);
      second = plate.slice(3).replace(/\D/g, '');
      plate = first + '-' + second;
      return plate;
    },
    CNPJ: function maskCNPJ(str) {
      return str.replace(/\D/g, "").replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/gm, "\$1.\$2.\$3\/\$4\-\$5");
    },
    Number: function maskNumber(num) {
      num = num.replace(/\D/g, "");
      return num;
    },
  };
})();

